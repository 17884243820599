import React from "react";
import NextLink from "next/link";
import { chunk } from "lodash/fp";
import listingPath from "storefront/paths/listing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import {
  Carousel,
  CarouselContent,
  CarouselNavDots,
  CarouselNavNext,
  CarouselNavPrev,
  CarouselSlide,
} from "storefront/components/CarouselNext";
import ImageV2 from "storefront/components/ImageV2";
import Footnote from "storefront/components/Footnote";
import Price from "storefront/components/Price";
import styles from "./index.module.scss";

export const MIN_LISTINGS = 5;

type Props = {
  listings: Array<AlgoliaListing>;
  onItemClick?: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
};

const ListingItemStack = ({ listings, onItemClick }: Props) => (
  <Carousel loop>
    <CarouselContent>
      {chunk(MIN_LISTINGS, listings).map((slide) => {
        return (
          <CarouselSlide key={`${slide[0].id}-carousel`}>
            <div className={styles.slide}>
              {slide.map((listing) => {
                return (
                  <NextLink
                    key={`${listing.id}-link`}
                    href={listingPath(listing)}
                    target="_blank"
                    rel="noreferrer"
                    onClick={onItemClick}
                    className={styles.listing}
                  >
                    <ImageV2
                      src={listing.coverPhoto.url}
                      alt={listing.title}
                      width={72}
                      height={72}
                      fit="crop"
                      rotate={listing.coverPhoto.rotate}
                      className={styles.thumbnail}
                    />

                    <div className={styles.info}>
                      <Footnote>{listing.title}</Footnote>

                      <Price size="small" listing={listing} />
                    </div>
                  </NextLink>
                );
              })}
            </div>
          </CarouselSlide>
        );
      })}
    </CarouselContent>

    <div className={styles.nav}>
      <CarouselNavPrev className={styles.caret} />
      <CarouselNavDots className={styles.dots} />
      <CarouselNavNext className={styles.caret} />
    </div>
  </Carousel>
);

export default ListingItemStack;
