import { Id } from "storefront/lib/Id";
import { CollectionDisplay } from "storefront/Collection";

type Properties = {
  name: string;
  type: string;
  position: number;
  collectionExternalId: string | null;
  algorithmId: string | null;
  topDesignerIds: Array<Id> | null;
};

export type HomepageContentClickedEvent = {
  object: "homepage_content";
  action: "clicked";
  properties: Properties;
};

const homepageContentClicked = ({
  name,
  type,
  position,
  collectionExternalId,
  algorithmId,
  topDesignerIds,
}: Properties): HomepageContentClickedEvent => ({
  object: "homepage_content",
  action: "clicked",
  properties: {
    name,
    type,
    position,
    collectionExternalId,
    algorithmId,
    topDesignerIds,
  },
});

export const homepageCollectionClicked = (
  collection: CollectionDisplay,
  algorithmId: string,
  position: number,
): HomepageContentClickedEvent =>
  homepageContentClicked({
    name: collection.title,
    type: "AGC",
    position,
    collectionExternalId: collection.externalId,
    algorithmId,
    topDesignerIds: collection.topDesigners.map((d) => d.id),
  });

export const homepageModuleClicked = (
  name: string,
  type: string,
  position: number,
): HomepageContentClickedEvent =>
  homepageContentClicked({
    name,
    type,
    position,
    collectionExternalId: null,
    algorithmId: null,
    topDesignerIds: null,
  });

export default homepageContentClicked;
