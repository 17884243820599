import React from "react";
import { PageType } from "storefront/Analytics/Event";
import { CollectionDisplay } from "storefront/Collection";
import CollectionModule from "storefront/components/CollectionModule";

type Props = {
  collection: CollectionDisplay;
  algorithmId: string;
  position: number;
  pageType: PageType;
};

const PersonalizedCollectionSingle = ({
  collection,
  algorithmId,
  position,
  pageType,
}: Props) => {
  return (
    <CollectionModule
      collection={collection}
      position={position}
      pageType={pageType}
      algorithmId={algorithmId}
      key={`${collection.id}-${collection.externalId}-${collection.lastRefreshedAt}-${position}`}
    />
  );
};

export default PersonalizedCollectionSingle;
