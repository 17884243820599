import React from "react";
import { times } from "lodash/fp";
import { PHOTO_PLACEHOLDER_URL } from "storefront/Listing";
import ImageV2 from "storefront/components/ImageV2";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./index.module.scss";

const PHOTO_SLOTS = 5;

type Props = {
  photos: Array<{ url: string; rotate?: number }>;
};

const PreviewImageGrid = ({ photos }: Props) => (
  <div className={styles.images}>
    {times((index) => {
      const photo = photos[index];
      const renderOverlay =
        index === PHOTO_SLOTS - 2 || index === PHOTO_SLOTS - 1;

      return (
        <div
          className={styles[`image-${index + 1}`]}
          key={`${photo?.url}-${index + 1}`}
        >
          <div className={styles.imageWrapper}>
            <ImageV2
              src={photo?.url || PHOTO_PLACEHOLDER_URL}
              alt={photo?.url || PHOTO_PLACEHOLDER_URL}
              fill
              fit="crop"
              sizes="(max-width: 770px) 100vw, 373px"
              rotate={photo?.rotate}
              priority
            />
          </div>

          {renderOverlay ? (
            <div className={styles.viewMore}>+ View More</div>
          ) : null}
        </div>
      );
    }, PHOTO_SLOTS)}
  </div>
);

export default PreviewImageGrid;
