import React from "react";
import { PageType } from "storefront/Analytics/Event";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import fetchListingsByIds from "storefront/Algolia/Listings/fetchByIds";
import { Authentication } from "storefront/Authentication";
import getRecentlyViewed from "storefront/RecentlyViewed/get";
import useModuleAnalytics from "storefront/hooks/useModuleAnalytics";
import ContentGridModule from "storefront/components/ContentGridModule";
import { MIN_LISTINGS } from "storefront/components/ContentGridModule/ListingItemStack";

export function getRecentlyViewedListings(
  auth: Authentication,
): Promise<Array<AlgoliaListing>> {
  return getRecentlyViewed(auth.type === "Authenticated" ? auth.user.id : null)
    .then((ids) => {
      if (ids.size >= MIN_LISTINGS) {
        return fetchListingsByIds(Array.from(ids), "default");
      }
      return Promise.resolve([]);
    })
    .then((listings) => {
      if (listings.length >= MIN_LISTINGS) {
        return Promise.resolve(listings);
      }
      return Promise.resolve([]);
    });
}

type Props = {
  position: number;
  pageType: PageType;
  recentlyViewedListings: Array<AlgoliaListing>;
};

const EmbeddedRecentlyViewed = ({
  position,
  pageType,
  recentlyViewedListings,
}: Props) => {
  const analytics = useModuleAnalytics({
    name: "Recently Viewed",
    type: "Recently Viewed",
    position,
    from: pageType,
  });

  return (
    <ContentGridModule ref={analytics?.ref}>
      <ContentGridModule.Header title="Recently Viewed" />

      <ContentGridModule.ListingItemStack
        listings={recentlyViewedListings}
        onItemClick={analytics?.onClick}
      />
    </ContentGridModule>
  );
};

export default EmbeddedRecentlyViewed;
