import React from "react";
import { PageType } from "storefront/Analytics/Event";
import { CollectionDisplay } from "storefront/Collection";
import CollectionModule from "storefront/components/CollectionModule";
import styles from "./index.module.scss";

export const NUM_GRID_COLLECTIONS = 9;

type Props = {
  collections: Array<CollectionDisplay>;
  algorithmId: string;
  startingPosition: number;
  pageType: PageType;
};

const PersonalizedCollectionGrid = ({
  collections,
  algorithmId,
  startingPosition,
  pageType,
}: Props) => {
  return (
    <div className={styles.collections}>
      {collections.slice(0, NUM_GRID_COLLECTIONS).map((collection, index) => (
        <CollectionModule
          collection={collection}
          position={startingPosition + index}
          pageType={pageType}
          algorithmId={algorithmId}
          key={`${collection.id}-${collection.externalId}-${collection.lastRefreshedAt}`}
        />
      ))}
    </div>
  );
};

export default PersonalizedCollectionGrid;
