import React from "react";
import { Id } from "storefront/lib/Id";
import { PageType } from "storefront/Analytics/Event";
import { TypedEntry } from "storefront/Contentful/TypedEntry";
import { Capsule } from "storefront/Capsule";
import { getCapsuleById } from "storefront/GrailedAPI/v1/Capsules/getCapsules";
import useModuleAnalytics from "storefront/hooks/useModuleAnalytics";
import { ModuleLoading } from "storefront/components/Homepage/ContentfulHomepage/LoadingHomepageContent";
import ContentGridModule from "storefront/components/ContentGridModule";

export const MARKETING_COLLECTION = "moduleUserGeneratedCollections" as const;

export type MarketingCollectionModule = TypedEntry<
  typeof MARKETING_COLLECTION,
  { name: string; collectionId: Id; imageUrls: Array<string> }
>;

type Props = {
  entry: MarketingCollectionModule;
  position: number;
  pageType: PageType;
};

const MarketingCollection = ({ entry, position, pageType }: Props) => {
  const [capsule, setCapsule] = React.useState<Capsule | null>(null);

  React.useEffect(() => {
    getCapsuleById(entry.fields.collectionId).then((c) => setCapsule(c));
  }, [entry.fields.collectionId]);

  const analytics = useModuleAnalytics({
    name: capsule?.name || "",
    type: "Marketing Collection",
    position,
    from: pageType,
  });

  if (!capsule) return <ModuleLoading />;

  return (
    <ContentGridModule
      href={`/collections/${capsule.detailPage.slug}`}
      ref={analytics?.ref}
      onClick={analytics?.onClick}
    >
      <ContentGridModule.Header title={capsule.name} tag="From Grailed" />

      <ContentGridModule.PreviewImageGrid
        photos={entry.fields.imageUrls.map((url) => ({ url }))}
      />
    </ContentGridModule>
  );
};

export default MarketingCollection;
