import React from "react";
import NextLink from "next/link";
import cn from "classnames";
import Footnote from "storefront/components/Footnote";
import Headline from "storefront/components/Headline";
import PreviewImageGrid from "./PreviewImageGrid";
import ListingItemStack from "./ListingItemStack";
import WideImageWithPlacecard from "./WideImageWithPlacecard";
import styles from "./index.module.scss";

type HeaderProps = {
  title: string;
  tag?: string;
  tagClassName?: string;
};

const Header = ({ title, tag, tagClassName }: HeaderProps) => (
  <div className={styles.header}>
    {tag ? (
      <Footnote className={cn(styles.tag, tagClassName)}>{tag}</Footnote>
    ) : null}

    <Headline className={styles.title}>{title}</Headline>
  </div>
);

type Props = {
  children: React.ReactNode;
  variant?: "standard" | "doubleWide";
  href?: string;
  onClick?: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
  className?: string;
};

const ContentGridModule = React.forwardRef<HTMLDivElement, Props>(
  ({ children, variant, href, onClick, className }, ref) => {
    if (href) {
      return (
        <div
          ref={ref}
          className={cn(styles.module, {
            [styles.doubleWide]: variant === "doubleWide",
          })}
        >
          <NextLink
            href={href}
            onClick={onClick}
            className={cn(styles.link, className)}
          >
            {children}
          </NextLink>
        </div>
      );
    }

    return (
      <div ref={ref} className={styles.module}>
        {children}
      </div>
    );
  },
);

const ContentGridModuleWithComponents = Object.assign(ContentGridModule, {
  Header,
  PreviewImageGrid,
  ListingItemStack,
  WideImageWithPlacecard,
});

export default ContentGridModuleWithComponents;
