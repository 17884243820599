import { get } from "storefront/GrailedAPI/request";
import { Id } from "storefront/lib/Id";
import { Pagination } from "storefront/Pagination";
import { Capsule } from "storefront/Capsule";

export type Response = {
  metadata: {
    pagination: Pagination;
    totalCount: number;
    totalPages: number;
  };
  data: Array<Capsule>;
};

const unpack = (response: Response): Capsule | null =>
  response.data.length ? response.data[0] : null;

export const getCapsuleById = (id: Id): Promise<Capsule | null> =>
  get<Response>("/api/capsules", { ids: [id] }).then(unpack);

/**
 * @name GrailedAPI.v1.Capsules.getCapsules
 * @param {?number} page the number of the page to fetch (1-indexed)
 * @param {?number} itemCount number of capsules to return per page
 * @return {Promise<Response>} returns a promise that resolves to a page of published capsules
 */
const getCapsules = (
  page: number | null | undefined,
  itemCount: number | null | undefined,
): Promise<Response> =>
  get<Response>("/api/capsules", {
    page,
    itemCount,
  });

export default getCapsules;
