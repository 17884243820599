import classNames from "classnames";
import React, { SyntheticEvent, useState, useEffect } from "react";
import { keyBy } from "lodash/fp";
import { Resource, loading, completed, failed } from "storefront/lib/Resource";
import isPresent from "storefront/lib/isPresent";
import useAnalytics from "storefront/hooks/useAnalytics";
import useModuleAnalytics from "storefront/hooks/useModuleAnalytics";
import moduleClicked from "storefront/Analytics/EventCreators/moduleClicked";
import getDetailPages from "storefront/GrailedAPI/v1/DetailPages/getDetailPages";
import { TrackingItemProperties } from "storefront/Contentful/types";
import { TypedEntry } from "storefront/Contentful/TypedEntry";
import { CategoryDetailPage } from "storefront/DetailPage";
import Heading from "../Heading";
import CategoryItem from "./CategoryItem";
import css from "./CategoryGrid.module.scss";

export const CATEGORY_GRID = "moduleCategoryGrid" as const;

export type CategoryGridModule = TypedEntry<
  typeof CATEGORY_GRID,
  {
    name: string;
    title: string;
    categorySlugs: Array<string>;
  }
>;

type Props = {
  entry: CategoryGridModule;
  position: number;
  from: string;
  className?: string;
};

const sortBySlugs =
  (sortedSlugs: Array<string>) =>
  (unsortedPages: Array<CategoryDetailPage>): Array<CategoryDetailPage> => {
    const pagesBySlug = keyBy("slug", unsortedPages);
    return sortedSlugs.map((slug) => pagesBySlug[slug]).filter(isPresent);
  };

function CategoryGrid({ entry, position, from, className }: Props) {
  const { name, title, categorySlugs } = entry.fields;
  const { track } = useAnalytics();
  const [pages, setPages] =
    useState<Resource<Array<CategoryDetailPage>>>(loading);

  const analytics = useModuleAnalytics({
    name: entry.fields.title,
    type: "Category Grid",
    position,
    from,
  });

  useEffect(() => {
    getDetailPages<CategoryDetailPage>({
      slugs: categorySlugs,
      type: "CategoryDetailPage",
    })
      .then(({ data }) => data)
      .then(sortBySlugs(categorySlugs))
      .then(completed)
      .catch(failed)
      .then(setPages);
  }, [categorySlugs]);

  const trackClick =
    (properties: TrackingItemProperties) =>
    (_event: SyntheticEvent<unknown>): void => {
      track(
        moduleClicked({
          ...properties,
          from,
          moduleType: "Category Grid",
          moduleName: title,
          moduleNameContentful: name,
          modulePosition: position,
        }),
      );

      analytics?.onClick();
    };

  return (
    <div
      className={classNames("CategoryGridModule", css.root, className)}
      ref={analytics?.ref}
    >
      <Heading title={title} />

      {pages.type !== "Completed" ? (
        <div className={classNames(css.items, css.loading)} />
      ) : (
        <div className={css.items}>
          {pages.value.map((page, index) => (
            <CategoryItem
              key={page.slug}
              position={index}
              page={page}
              trackClick={trackClick}
              className={css.item}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default CategoryGrid;
