import React from "react";
import BodyEditorial from "storefront/components/BodyEditorial";
import Footnote from "storefront/components/Footnote";
import styles from "./index.module.scss";

type Props = {
  mobileImageUrl: string;
  desktopImageUrl: string;
  desktopImageAlt: string;
  tag: string;
  title: string;
  description: string;
};

const WideImageWithPlacecard = ({
  mobileImageUrl,
  desktopImageUrl,
  desktopImageAlt,
  tag,
  title,
  description,
}: Props) => (
  <div className={styles.content}>
    <picture>
      <source media="(max-width: 1200px)" srcSet={mobileImageUrl} />
      <source media="(min-width: 1201px)" srcSet={desktopImageUrl} />
      <img
        src={desktopImageUrl}
        alt={desktopImageAlt}
        className={styles.image}
      />
    </picture>

    <div className={styles.info}>
      <Footnote className={styles.tag}>{tag}</Footnote>
      <BodyEditorial className={styles.title}>{title}</BodyEditorial>
      <Footnote>{description}</Footnote>
    </div>
  </div>
);

export default WideImageWithPlacecard;
