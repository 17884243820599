import React from "react";
import { PageType } from "storefront/Analytics/Event";
import {
  EmbeddedFeature,
  EMBEDDED_RECENTLY_VIEWED,
  PERSONALIZED_COLLECTION_GRID,
  PERSONALIZED_COLLECTION_SINGLE,
  TICKER,
} from "storefront/Contentful/types";
import { CollectionDisplay } from "storefront/Collection";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import Ticker from "storefront/components/Ticker";
import PersonalizedCollectionGrid from "storefront/components/PersonalizedCollectionGrid";
import PersonalizedCollectionSingle from "storefront/components/PersonalizedCollectionSingle";
import RecentlyViewed from "storefront/components/EmbeddedRecentlyViewed";

type EmbeddedWrapperProps = {
  entry: EmbeddedFeature;
  position: number;
  pageType: PageType;
  collections?: Array<CollectionDisplay>;
  algorithmId?: string;
  recentlyViewedListings?: Array<AlgoliaListing>;
};

const EmbeddedWrapper = ({
  entry,
  position,
  pageType,
  collections,
  algorithmId,
  recentlyViewedListings,
}: EmbeddedWrapperProps) => {
  const { fields } = entry;
  const { moduleId } = fields;

  switch (moduleId) {
    case TICKER:
      return <Ticker text="🔥 Grails for You" />;
    case PERSONALIZED_COLLECTION_GRID:
      return (
        <>
          {collections ? (
            <PersonalizedCollectionGrid
              collections={collections}
              algorithmId={algorithmId || ""}
              startingPosition={position}
              pageType={pageType}
            />
          ) : null}
        </>
      );
    case PERSONALIZED_COLLECTION_SINGLE:
      return (
        <>
          {collections?.[0] ? (
            <PersonalizedCollectionSingle
              collection={collections[0]}
              algorithmId={algorithmId || ""}
              position={position}
              pageType={pageType}
            />
          ) : null}
        </>
      );
    case EMBEDDED_RECENTLY_VIEWED: {
      if (recentlyViewedListings?.length) {
        return (
          <RecentlyViewed
            position={position}
            pageType={pageType}
            recentlyViewedListings={recentlyViewedListings}
          />
        );
      }

      return (
        <>
          {collections?.[0] ? (
            <PersonalizedCollectionSingle
              collection={collections[0]}
              algorithmId={algorithmId || ""}
              position={position}
              pageType={pageType}
            />
          ) : null}
        </>
      );
    }

    default:
      ((_: never): void => {})(moduleId);
      return null;
  }
};

export default EmbeddedWrapper;
