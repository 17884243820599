import React from "react";
import { Asset } from "contentful";
import { PageType } from "storefront/Analytics/Event";
import { TypedEntry } from "storefront/Contentful/TypedEntry";
import useModuleAnalytics from "storefront/hooks/useModuleAnalytics";
import ContentGridModule from "storefront/components/ContentGridModule";

export const EDITORIAL = "valuePropItem";

export type EditorialModule = TypedEntry<
  typeof EDITORIAL,
  {
    name: string;
    tag: string;
    title: string;
    description: string;
    desktopImage: Asset;
    mobileImage: Asset;
    url: string;
  }
>;

type Props = {
  entry: EditorialModule;
  position: number;
  pageType: PageType;
};

const Editorial = ({ entry, position, pageType }: Props) => {
  const { tag, title, description, desktopImage, mobileImage, url } =
    entry.fields;

  const analytics = useModuleAnalytics({
    name: title,
    type: "Editorial",
    position,
    from: pageType,
  });

  return (
    <ContentGridModule
      href={url}
      variant="doubleWide"
      ref={analytics?.ref}
      onClick={analytics?.onClick}
    >
      <ContentGridModule.WideImageWithPlacecard
        mobileImageUrl={mobileImage.fields.file.url}
        desktopImageUrl={desktopImage.fields.file.url}
        desktopImageAlt={desktopImage.fields.description}
        tag={tag}
        title={title}
        description={description}
      />
    </ContentGridModule>
  );
};

export default Editorial;
