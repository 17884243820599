import React from "react";
import { isEmpty } from "lodash/fp";
import { PageType } from "storefront/Analytics/Event";
import { CollectionDisplay } from "storefront/Collection";
import useModuleAnalytics from "storefront/hooks/useModuleAnalytics";
import ContentGridModule from "storefront/components/ContentGridModule";
import styles from "./index.module.scss";

type Props = {
  collection: CollectionDisplay;
  algorithmId: string;
  position: number;
  pageType: PageType;
  className?: string;
};

const CollectionModule = ({
  collection,
  algorithmId,
  position,
  pageType,
  className,
}: Props) => {
  const analytics = useModuleAnalytics({
    collection,
    algorithmId,
    position,
    from: pageType,
  });

  return (
    <ContentGridModule
      href={`/collections/${collection.id}`}
      ref={analytics?.ref}
      onClick={analytics?.onClick}
      className={className}
    >
      <ContentGridModule.Header
        title={collection.title}
        tag={
          !isEmpty(collection.topDesigners)
            ? collection.topDesigners
                .map((d) => d.name)
                .join(", ")
                .concat(" +More")
            : undefined
        }
        tagClassName={styles.topDesigners}
      />

      <ContentGridModule.PreviewImageGrid photos={collection.displayPhotos} />
    </ContentGridModule>
  );
};

export default CollectionModule;
